.fish-logo {
    margin: 0 1rem 0 auto;
    display: block;
}

.svg-container {
    display: flex;
    align-items: center;
    height: 100%;
}
